<template>
    <div class="device-manage-page">
        <div class="filter-bar-box">
            <el-form  ref="form" :model="filter" label-width="80px" inline>
                <el-form-item prop="phoneNo" label="手机号">
                    <el-input v-model="filter.phoneNo" size="small" placeholder="请输入手机号" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="danger" @click="getAdminList" size="small">查 询</el-button>
                    <el-button size="small" @click="addUser">新 增</el-button>
                </el-form-item>
            </el-form>
        </div>
           <main class="main">
            <el-table :data="tableData" style="width: 100%" size="small">
                <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="userName"
                    label="姓名"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="domainName"
                    label="域账号"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="phoneNo"
                    label="手机号"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="添加时间"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="操作"
                    align="center"
                    width="340"
                    >
                    <template slot-scope="scope">
                        <a style="color:red" @click="handleOperation(1,scope.row)">删除</a>
                        <a v-if="scope.row.status != 1" style="color:#0400ff;margin-left:8px" @click="handleOperation(2,scope.row)">禁用</a>
                        <a v-if="scope.row.status != 0" style="color:#27f302;margin-left:8px" @click="handleOperation(3,scope.row)">启用</a>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <div class="pagination-box mt10">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 40, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
                </el-pagination>
            </div> -->
        </main>

        <el-dialog
            title="新增用户"
            :visible.sync="centerDialogVisible"
            width="30%"
            :rules="rules"
            center>
              <el-form :rules="rules" ref="addUserForm" :model="userDetails" label-width="80px" style="width:100%">
                <el-form-item prop="userName" label="姓名">
                      <el-input v-model="userDetails.userName" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item prop="domainName" label="域账号">
                      <el-input v-model="userDetails.domainName" placeholder="请输入域账号"></el-input>
                </el-form-item>
                <el-form-item prop="phoneNo" label="手机号">
                      <el-input v-model="userDetails.phoneNo" placeholder="请输入手机号"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addAdminUser" >添 加</el-button>
                <el-button @click="centerDialogVisible = false" >取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { getAdminList, addAdminUser, disableAdmin, enableAdmin, deleteAdmin } from '@/services'
export default {
    data() {
        return {
            centerDialogVisible: false,
            filter: {
                phoneNo: ''
            },
            auditStatusMap: {
                '0': '待审核',
                '1': '已通过',
                '2': '未通过',
                '3': '已删除',
            },
            tableData: [],
            pageSize: 10,
            pageNo: 1,
            pageTotal: 0,
            userDetails: {
                userName: '',
                domainName: '',
                phoneNo: '',
            },
            rules: {
                userName: [
                    { required: true, message: '请输入用户名称', trigger: 'blur' },
                ],
                domainName: [
                    { required: true, message: '请输入域账号', trigger: 'blur' }
                ],
                phoneNo: [
                    { required: true, message: '请输入手机号', trigger: 'blur' }
                ],
            }
        }
    },

    created() {
        this.getAdminList()
    },

    methods: {
        getAdminList() {
            getAdminList({
                ...this.filter,
            }).then(res => {
                if(res.code == 200) {
                    let content = res.content || []
                    this.tableData = content
                } else {
                    this.$message.error(res.message)
                }
            })
        },

        /**
         * 更新状态
         * @author tanguozheng
         * @date 2021-12-06 14:30
         */
        updateDeviceStatus(equipCode, auditStatus) {
            updateDeviceStatus({
                equipCode: equipCode,
                auditStatus: auditStatus
            }).then(res => {
                 if(res.code == 200) {
                    this.getAdminList()
                    this.$message.success('修改成功')
                } else {
                    this.$message.error(res.message)
                }
            })
        },
          /**
         * 修改每页显示条数
         * @author tanguozheng
         * @date 2021-07-12 19:15
         * @param {*}
         * @returns
         */
        handleSizeChange(size) {
            this.pageNo = 1
            this.pageSize = size
            this.getAdminList()
        },

        /**
         * 翻页
         * @author tanguozheng
         * @date 2021-07-12 19:15
         * @param {*}
         * @returns
         */
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage
            this.getAdminList()
        },
        
        //重置
        resetForm() {
            this.$refs['form'].resetFields();
        },

        /**
         * 点击查看
         * @author tanguozheng
         * @date 2021-12-06 14:29
         * @param {*}
         * @returns
         */
        addUser(row) {
            this.centerDialogVisible = true
            this.userDetails = {
                userName: '',
                domainName: '',
                phoneNo: '',
            }
        },

        handleOperation(type, row) {
            let fn = null
            if(type === 1) { // 删除
                fn = deleteAdmin
            } else if(type === 2) { // 警用
                fn = disableAdmin
            } else if(type === 3) { // 启用
                fn = enableAdmin
            }
            fn({
               phoneNo: row.phoneNo 
            }).then(res => {
                if(res.code == 200) {
                    this.getAdminList()
                    this.$message.success('操作成功')
                } else {
                    this.$message.error(res.message)
                }
            })
        },

        /**
         * 新增超管用户
         * @author tanguozheng
         * @date 2021-12-06 14:50
         */
        addAdminUser(row, status) {
            this.$refs['addUserForm'].validate((valid) => {
                if (valid) {
                    addAdminUser({
                        ...this.userDetails
                    }).then(res => {
                        if(res.code == 200) {
                            this.centerDialogVisible = false
                            this.getAdminList()
                            this.$message.success('新增成功')
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                } else {
                    return false;
                }
            });
        },
    }

}
</script>

<style lang="scss">
.device-manage-page{
    padding: 20px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;

    .filter-bar-box{
        padding-top: 22px;

        background-color: #ffffff;
    }

    .main {
        background-color: #ffffff;
        margin-top: 22px;
        flex: 1;
        padding: 15px;
    }

}
.dialog-line-item {
    display: flex;
    margin-top: 20px;
    >div{
        width: 80px;
        text-align: right;
        margin-right: 20px;
    }

    img{
        width: 65%;
        height: 168px;
    }
}
</style>

